@use "sass:color";
@use 'kendo-theme/design-system.scss' as theme;

$selection-bg: #cce1f7;

::selection {
    background-color: $selection-bg;
    color: theme.$kendo-body-text;
}

a {
    color: inherit;
    text-decoration: none;
}

textarea {
    resize: vertical;
}

strong,
dt {
    font-weight: theme.$kendo-font-weight-bold;
}

table {
    border-collapse: collapse;
}

dfn {
    --definition-background-color: #{theme.$kendo-color-secondary};
    --selected-definition-color: #{theme.$wcag-light};
    --definition-icon: url('../icons/term-icon-blue.svg');
    --definition-hover-icon: url('../icons/term-icon-white.svg');

    font-style: normal;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    transition: color 200ms ease-out;
    padding: 0 1px;

    &[data-term-id] {
        cursor: pointer;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: var(--definition-background-color);
        transition: height 200ms ease-out;
    }

    &::after {
        content: var(--definition-icon);
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 3px;
        vertical-align: middle;
    }

    &:hover,
    &.k-icp-selected {
        color: var(--selected-definition-color);

        &::before {
            height: calc(100% + 1px);
        }

        &::after {
            content: var(--definition-hover-icon);
        }
    }

    .light-dfn-area & {
        --definition-background-color: #{theme.$link-text-alt};
        --selected-definition-color: #{theme.$wcag-dark};
        --definition-icon: url('../icons/term-icon-white.svg');
        --definition-hover-icon: url('../icons/term-icon-black.svg');
    }
}

.top-nav {
    padding: 12px 24px 11px;
    border-bottom: 1px solid theme.$kendo-component-border;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 24px;
}

.top-nav-section {
    display: flex;
    align-items: center;
    gap: 24px;
}

.top-nav-section--main {
    flex-grow: 1;
    min-width: 0;
}

.top-nav-logo {
    display: block;
    height: 100%;
}

.top-nav-logo-image {
    height: 100%;
}

.top-nav-editor {
    min-width: 0;
    flex-grow: 1;
    overflow: hidden;

    .highlight-target-active {
        margin: 0 -4px;
        padding: 8px 4px;
    }
}

.top-nav-editor-breadcrumb {
    flex-basis: 64px;
    transition: flex-basis 300ms ease-out, opacity 300ms ease-out;
    overflow: hidden;
    flex-shrink: 0;
}

.top-nav-editor--in-edit {
    .top-nav-editor-breadcrumb {
        flex-basis: 0;
        opacity: 0;
    }
}

.top-nav-popover {
    width: 360px;
}

@media screen and (max-width: 576px) {
    .top-nav {
        padding: 12px 16px 11px;
        gap: 16px;
    }

    .top-nav-section {
        gap: 16px;
    }
}

//Toastify library styles
:root {
    --toastify-text-color-light: #{theme.$wcag-dark};
    --toastify-text-color-dark: #{theme.$wcag-light};
    --toastify-color-progress-light: #{theme.$kendo-color-secondary};
    --toastify-font-family: #{theme.$kendo-font-family};
    --toastify-toast-offset: 16px;
    --toastify-toast-bd-radius: 4px;
    --toastify-toast-width: 360px;
    --toastify-color-progress-bgo: 0;
    --toastify-z-index: 9999;
}

.Toastify__progress-bar--wrp {
    height: 6px;
}

.Toastify__progress-bar {
    opacity: 1;
}

//Fix sliding animation to correctly scale toast notifications
@keyframes Toastify__slideInRight {
    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, calc(var(--y) / 3), 0) scale(var(--s));
    }
}

@keyframes Toastify__slideOutRight_icp_collapsed {
    from {
        transform: translate3d(0, calc(var(--y) / 3), 0) scale(var(--s));
    }
    to {
        visibility: hidden;
        transform: translate3d(110%, calc(var(--y) / 3), 0) scale(var(--s));
    }
}

.notifications-popup {
    padding: 16px 16px 22px 16px;
    box-shadow: 1px 1px 16px 2px rgba(0, 0, 0, 0.3);
    margin-bottom: 0;

    &[data-collapsed='true'] {
        transform: translate3d(0, calc(var(--y) / 3), 0) scale(var(--s));

        &.Toastify__slide-exit--top-right,
        &.Toastify__slide-exit--bottom-right {
            animation-name: Toastify__slideOutRight_icp_collapsed;
        }
    }

    &-progress-bar {
        background-color: theme.$kendo-color-secondary;
    }

    &-body {
        padding-block: 0;
        padding-left: 0;
        padding-right: 12px;
    }
}

.notifications-popover {
    min-width: 360px;

    .k-popover {
        max-height: calc(100vh - 62px);
    }

    .k-popover-inner,
    .k-popover-body {
        min-height: 0;
        display: flex;
        flex-direction: column;
    }

    .notifications-scroll-container {
        max-height: 100%;
        overflow: auto;
    }
}

.hex-top-right-bg {
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 34% auto;
    background-image: url('../images/hexagons-top-right-bg.svg');
}

.hex-bottom-right-bg {
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 34% auto;
    background-image: url('../images/hexagons-bottom-right-bg.svg');
}

$verticalSeparatorHeight: 90%;
$shortVerticalSeparatorHeight: 80%;
.vertical-separator {
    align-self: stretch;
    width: 1px;
    position: relative;
    flex-shrink: 0;

    &::before {
        display: block;
        content: '';
        width: 100%;
        height: $verticalSeparatorHeight;

        position: absolute;
        top: (100% - $verticalSeparatorHeight) / 2;
        background-color: theme.$kendo-component-border;
    }
}

.vertical-separator-short::before {
    height: $shortVerticalSeparatorHeight;
    top: (100% - $shortVerticalSeparatorHeight) / 2;
}

.full-horizontal-separator {
    width: 100%;
    height: 1px;
    background-color: theme.$kendo-component-border;
    flex: 1 0 1px;
}

.k-separator-darker {
    border-color: rgba(theme.$kendo-component-border, 0.16);
}

.dot-separator {
    width: 4px;
    height: 4px;
    background-color: theme.$kendo-disabled-text;
    border-radius: 50%;
}

.dot-separator-current-color {
    background-color: currentColor;
}

.cards-relaxed-grid {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    align-items: start;
}

.startup-card:hover {
    .startup-card-options {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
    }
}

.horizontally-flipped-tooltip {
    transform: rotateY(180deg);

    .k-tooltip-content {
        transform: rotateY(-180deg);
    }
}

.startup-card-options {
    position: absolute;
    top: 8px;
    right: 8px;
    color: theme.$wcag-light;
    background-color: theme.$kendo-color-dark;
    box-shadow: theme.$elevated-shadow-sm;
    visibility: hidden;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 200ms ease-in, visibility 200ms ease-in, transform 200ms ease-in;
}

.side-nav {
    width: 140px;

    .k-button-flat-base {
        &::before {
            transition: none;
        }

        &.active::before {
            background-color: theme.$kendo-color-primary;
            opacity: 0.5;
            z-index: -1;
        }
    }
}

.side-panel {
    border-left: 1px solid theme.$kendo-component-border;
    display: flex;
    flex-direction: column;
}

.platform-notifications-group {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100000;
    padding-bottom: 16px;
}

.platform-notifications-list {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.idea-canvas {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-areas:
        'Problem Problem Solution Solution UniqueValueProposition UniqueValueProposition UnfairAdvantage UnfairAdvantage CustomerSegments CustomerSegments'
        'Problem Problem KeyMetrics KeyMetrics UniqueValueProposition UniqueValueProposition Channel Channel CustomerSegments CustomerSegments'
        'CostStructure CostStructure CostStructure CostStructure CostStructure RevenueStreams RevenueStreams RevenueStreams RevenueStreams RevenueStreams';
    gap: 1px;
    background-color: theme.$kendo-component-border;
    padding: 1px;
    flex-grow: 1;
}

.canvas-box {
    background-color: theme.$wcag-light;
}

.canvas-box-index {
    color: theme.$kendo-base-bg;
    font-weight: 500;
    font-size: 48px;
    line-height: 1;
    user-select: none;
}

.canvas-box-activity {
    background: rgba(theme.$kendo-color-secondary, 0.08);
    border-radius: 4px 4px 0px 4px;
}

.typing-indicator {
    .dot {
        background: theme.$kendo-subtle-text;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        animation: wave 1.3s ease-out infinite;
        &:nth-child(2) {
            animation-delay: -1.1s;
        }

        &:nth-child(3) {
            animation-delay: -0.9s;
        }
    }

    @keyframes wave {
        0%,
        60%,
        100% {
            transform: initial;
        }

        30% {
            transform: translateY(-5px);
        }
    }
}

.canvas-box-problem {
    grid-area: Problem;
}

.canvas-box-solution {
    grid-area: Solution;
}

.canvas-box-keymetrics {
    grid-area: KeyMetrics;
}

.canvas-box-uniquevalueproposition {
    grid-area: UniqueValueProposition;
}

.canvas-box-unfairadvantage {
    grid-area: UnfairAdvantage;
}

.canvas-box-channel {
    grid-area: Channel;
}

.canvas-box-customersegments {
    grid-area: CustomerSegments;
}

.canvas-box-coststructure {
    grid-area: CostStructure;
}

.canvas-box-revenuestreams {
    grid-area: RevenueStreams;
}

.canvas-box-header {
    font-weight: 500;
    font-size: 16px;
    padding: 8px 8px 0;
}

.canvas-box-content {
    padding: 8px 8px 2px;
}

.canvas-box-footer {
    padding: 0 8px 8px;
}

.canvas-box-solo {
    border: 1px solid theme.$kendo-component-border;
    grid-area: auto;
}

.draggable-item-cue {
    pointer-events: none;
    z-index: 100;
}

.draggable-item-placeholder {
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: theme.$wcag-light;
        border: 1px dashed rgba(theme.$wcag-dark, 0.5);
        border-radius: inherit;
        z-index: 1;
    }

    &:focus-visible {
        box-shadow: none;
    }
}

$canvas-overlay-color: rgba(theme.$wcag-dark, 0.04);
.canvas-box-drag-and-drop-overlay {
    position: fixed;
    background-color: $canvas-overlay-color;
}

.canvas-box-overlaid {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        display: block;
        inset: 0;
        background-color: $canvas-overlay-color;
        transition: transform 400ms ease-out, opacity 300ms ease-out;
        z-index: 1;
    }

    &-collapsed::after {
        transform: scale(0);
    }

    &-hidden::after {
        opacity: 0;
    }
}

.canvas-box-item {
    position: relative;
    border-radius: 4px;
    margin-bottom: 6px;

    &:focus-visible {
        outline: none;
        box-shadow: theme.$elevated-shadow-md;
    }
}

.canvas-box-item-menu {
    margin: -1px 0;
}

.inline-editor-actions {
    visibility: hidden;

    &:has(.k-active) {
        visibility: visible;
    }
}

.inline-editor-view-has-actions {
    &:hover {
        .inline-editor-actions {
            visibility: visible;
        }
    }
}

.inline-editor-view-has-actions {
    &:hover {
        .interview-script-viewer-history {
            visibility: hidden;
        }
    }
}

.inline-editor-view-has-actions {
    &:has(.inline-editor-actions .k-active) {
        .interview-script-viewer-history {
            visibility: hidden;
        }
    }
}

.sortable-in-progress {
    .inline-editor-actions {
        visibility: hidden !important;
    }
}

.inline-editor-view-editable {
    &:hover {
        border-color: theme.$kendo-picker-hover-border;
    }
}

.canvas-box-item {
    .inline-editor-view-editable {
        &:hover {
            border-color: theme.$kendo-component-border;
        }
    }

    .inline-editor-view {
        background-color: theme.$kendo-base-bg;
        border-color: theme.$kendo-base-bg;
    }
}

.canvas-box-item-secondary {
    .inline-editor-view {
        color: theme.$kendo-subtle-text;
    }

    .inline-editor-view-editable:hover {
        color: inherit;
    }
}

.inline-editor-edit-has-actions .inline-editor-padded {
    padding-right: 18px;
}

.inline-editor-has-label.inline-editor-padded > textarea {
    padding-top: 22px;
}

.inline-editor-has-label.inline-editor-padded > .k-input-inner {
    padding-top: 22px;
}

.inline-editor-text-area {
    textarea.k-input-inner {
        overflow: hidden !important; // Fix - when the placeholder is on two lines a scrollbar shows up

        &::placeholder {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.k-input-md textarea.k-input-inner {
        min-height: 30px; // Fix bug with kendo auto resizable text area - in some cases the height is calculated to be 0
    }

    &.k-input-sm textarea.k-input-inner {
        min-height: 22px; // Fix bug with kendo auto resizable text area - in some cases the height is calculated to be 0
    }

    &.k-input-lg textarea.k-input-inner {
        min-height: 38px; // Fix bug with kendo auto resizable text area - in some cases the height is calculated to be 0
    }
}

.canvas-box-item-colors {
    position: absolute;
    top: 0;
    left: 8px;
    right: 8px;
    height: 3px;
}

.palette-color {
    border: 1px solid theme.$component-border-solid;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}

.palette-color-sm {
    width: 12px;
    height: 12px;
}

.palette-color-collection {
    transform: rotateZ(45deg);
    overflow: hidden;
}

.palette-color-marked {
    position: relative;

    &::after {
        display: block;
        position: absolute;
        border: 1px solid theme.$kendo-picker-outline-hover-border;
        content: '';
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        border-radius: 50%;
    }
}

.label-picker-toggle-button {
    position: absolute;
    bottom: 4px;
    right: 2px;
    z-index: 1;
}

.relate-items-popover {
    width: 275px;
}

@mixin max-lines-n($max-lines) {
    text-overflow: ellipsis;
    line-clamp: $max-lines;
    -webkit-line-clamp: $max-lines;
    overflow: hidden;
    display: flex;
    display: -webkit-box;
    box-orient: vertical;
    -webkit-box-orient: vertical;
}

.max-lines-2 {
    @include max-lines-n(2);
}

.max-lines-3 {
    @include max-lines-n(3);
}

.max-lines-4 {
    @include max-lines-n(4);
}

.max-lines-7 {
    @include max-lines-n(7);
}

.k-textarea-no-resize {
    textarea {
        resize: none;
    }
}

.coming-soon-plant-img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}

.top-nav-notification {
    padding: 8px 12px 8px 10px;
    border-left: 2px solid transparent;
    border-bottom: 1px solid theme.$kendo-component-border;
    user-select: none;
}

.top-nav-notification-unread {
    background-color: rgba(theme.$kendo-color-secondary, 0.04);
    border-left-color: theme.$kendo-color-secondary;

    cursor: pointer;
}

.icon-button-error {
    .k-button-icon {
        color: theme.$kendo-color-error;
    }
}

.icon-button-success {
    .k-button-icon {
        color: theme.$kendo-color-success;
    }
}

.loading-inline-md {
    height: 32px;
    vertical-align: middle;
}

.loading-indicator {
    animation: fadeIn 100ms linear;
}

.commitment-question-collection {
    width: 720px;
}

.commitment-question {
    border-radius: 24px;
    border: 1px solid rgba(theme.$wcag-dark, 0.24);
    width: 440px;
    max-width: 100%;
    position: relative;
    z-index: 1;
    background-color: theme.$wcag-light;
}

.commitment-question-index {
    font-size: 48px;
    line-height: 1;
    user-select: none;
    color: theme.$kendo-base-bg;
    position: absolute;
    right: 17px;
    bottom: 15px;
}

.page-sticky-header {
    top: -24px;
    position: sticky;
    background-color: theme.$wcag-light;
    z-index: 150;
}

.page-sticky-header-stuck {
    box-shadow: theme.$bar-shadow;
}

$task-content-width: 996px;
.task-header {
    top: -16px;
    display: grid;
    grid-template-columns: minmax(120px, 1fr) minmax(0, $task-content-width) minmax(120px, 1fr);
}

.sticky-footer {
    position: sticky;
    bottom: -12px;
    z-index: 100;
}

.task-footer {
    bottom: -24px;
}

.sticky-footer-stuck {
    background-color: theme.$wcag-light;
    box-shadow: theme.$bar-shadow-reversed;
}

.task-content-wrapper {
    width: $task-content-width;
}

.task-steps-nav {
    position: sticky;
    top: 0px;
}

.task-step {
    transition: transform 600ms ease-out, opacity 600ms ease-out, visibility 600ms ease-out;
}

.task-step-hidden {
    display: none;
}

.task-step-to-reveal {
    opacity: 0;
    visibility: hidden;
    transform: translateY(300px);
}

.task-guidance-graphic-layout {
    grid-template-columns: 1fr auto;

    > :nth-child(2) {
        align-self: end;
    }
}

.canvas-box-in-editor {
    min-height: 200px;
}

.canvas-box-related {
    &--md {
        .canvas-box-header {
            font-size: 14px;
        }
    }

    &--sm {
        .canvas-box-header {
            font-size: 12px;
            font-weight: 600;
        }

        .canvas-box-item > .k-input {
            font-size: 12px;
        }
    }

    .inline-editor-view {
        color: theme.$kendo-subtle-text;
    }

    .canvas-box-footer {
        padding-bottom: 0;
    }

    .canvas-box-index {
        font-size: 24px;
        color: theme.$component-border-solid;
    }
}

.formatted-links-area {
    a {
        color: theme.$kendo-link-text;

        &:hover {
            color: theme.$kendo-link-hover-text;
        }
    }
}

.formatted-content-area {
    h1 {
        margin-bottom: 32px;
    }

    h2 {
        margin-bottom: 24px;
    }

    h3 {
        margin-bottom: 12px;
    }

    p,
    .formatted-content {
        margin-bottom: 8px;
    }

    .section {
        margin-bottom: 32px;
    }

    ul,
    ol {
        margin: 0;
        padding-left: 20px;
        margin-bottom: 8px;

        &.compressed-list > li {
            margin-bottom: 0;
        }
    }

    li {
        margin-bottom: 8px;
    }

    a {
        color: theme.$kendo-link-text;

        &:hover {
            color: theme.$kendo-link-hover-text;
        }
    }

    :last-child {
        margin-bottom: 0;
    }
}

.list-item-like {
    position: relative;
    padding-left: 20px;

    &::before {
        display: inline-block;
        content: '•';
        position: absolute;
        left: 10px;
        transform: translateX(-100%);
    }
}

.definition-popup {
    background-color: color.mix(theme.$kendo-color-secondary, theme.$wcag-light, 4);
    width: 480px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: theme.$elevated-shadow-md;
}

$highlight-target-shadow: 0 0 0 1px theme.$kendo-color-dark;
.highlight-target-active {
    --highlight-box-shadow: #{$highlight-target-shadow};
    animation: highlight-animation 2400ms ease-out;
}

.highlight-target-inset {
    --highlight-box-shadow: inset #{$highlight-target-shadow};
}

.create-startup-modal-content {
    max-width: 864px;
    margin-inline: 48px;
    margin-bottom: 32px;
}

.hints-tooltip-popover {
    border-radius: 4px;
    margin-right: 24px;
}

.journey-content-wrapper {
    width: 944px;
}

.journey-phase {
    --journey-phase-color: #b4bfd7;
    --journey-node-title-color: #68789a;
}

.journey-phase-1 {
    --journey-phase-color: #b1d6e4;
    --journey-node-title-color: #5c8ea1;
}

.journey-phase-2 {
    --journey-phase-color: #a8ceba;
    --journey-node-title-color: #53a579;
}

.journey-phase-3 {
    --journey-phase-color: #d6dec5;
    --journey-node-title-color: #718f30;
}

.journey-phase-4 {
    --journey-phase-color: #dac972;
    --journey-node-title-color: #cfb844;
}

.journey-phase-colored {
    background-color: var(--journey-phase-color);
}

.journey-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid theme.$kendo-disabled-text;
    width: 0;
    height: 0;
}

.journey-phase-title {
    width: 208px;
    position: sticky;
    top: 0px;
    z-index: 1;
}

.journey-phase-title-stuck {
    box-shadow: 0px 4px 4px rgba(206, 206, 206, 0.25);
}

.journey-path-wrapper {
    z-index: 0;
}

.journey-task-node {
    border-radius: 12px;
    border: 1px solid transparent;
}

.journey-task-node-locked {
    color: theme.$kendo-subtle-text;

    .journey-task-node-icon-wrapper {
        background-color: theme.$component-border-solid;
    }

    .journey-task-node-title {
        color: inherit;
    }
}

.journey-task-node-interactive {
    cursor: pointer;

    &:hover {
        box-shadow: theme.$kendo-popup-shadow;
        border-color: var(--journey-phase-color);
    }
}

.journey-task-node-start {
    border-top-left-radius: 0;
}

.journey-task-node-end {
    border-top-right-radius: 0;
}

.journey-task-node-interactive.journey-task-node-highlighted {
    &,
    &:hover {
        border-color: theme.$kendo-color-secondary;
    }

    .journey-task-node-icon-wrapper {
        background-color: theme.$kendo-color-secondary;
        color: theme.$wcag-light;
    }

    .journey-task-node-title {
        color: theme.$kendo-color-secondary;
    }
}

.journey-task-node-icon-wrapper {
    background-color: var(--journey-phase-color);
}

.journey-task-node-title {
    color: var(--journey-node-title-color);
}

.journey-task-node-variations-popup {
    max-width: 240px;
}

.journey-milestone-node-title {
    position: relative;

    border: 1px solid var(--journey-phase-color);
    border-radius: 2px;
    padding: 2px 16px 2px 8px;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        top: 0;
        bottom: 0;
        right: 0;
        border-right: 10px solid var(--journey-phase-color);
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        top: 0;
        bottom: 0;
        right: -1.5px;
        border-right: 10px solid theme.$wcag-light;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
    }

    &-flipped {
        padding-left: 16px;
        padding-right: 8px;

        &::before {
            right: auto;
            left: 0;
            border-right: none;
            border-left: 10px solid var(--journey-phase-color);
        }

        &::after {
            right: auto;
            left: -1.5px;
            border-right: none;
            border-left: 10px solid theme.$wcag-light;
        }
    }
}

.journey-milestone-node-title-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: var(--journey-phase-color);
    z-index: -1;
}

.journey-node-column {
    width: 280px;
    position: relative;
}

.journey-node {
    position: absolute;
    padding: 16px;
    width: 100%;
}

.journey-variation-label {
    position: absolute;
    box-shadow: theme.$elevated-shadow-sm;
}

// .back-to-task-btn-interaction-wrapper {
//     position: fixed;
//     top: 88px;
//     padding-right: 24px;
//     right: 0;
//     transition: transform 300ms ease-out;
//     transform: translateX(calc(100% - 36px));

//     &:hover {
//         transform: translateX(0);
//     }
// }

$back-to-task-button-radius: 20px;
.back-to-task-btn-location-wrapper {
    position: sticky;
    top: 0;
    z-index: 200;
}

.back-to-task-btn-placeholder {
    position: absolute;
    top: 0;
    right: -24px;

    // Add space to show the focus outline of the button
    padding: 3px 0 3px 3px;
    margin: -3px 0 -3px -3px;

    overflow-x: hidden;

    .back-to-task-btn-wrapper--collapsed {
        transform: translateX(calc(100% - 36px));
    }

    .back-to-task-btn-wrapper {
        transition: transform 300ms ease-out;

        padding-right: 24px;

        background-color: theme.$wcag-light;
        border-top-left-radius: $back-to-task-button-radius;
        border-bottom-left-radius: $back-to-task-button-radius;

        &:hover {
            transform: translateX(0);
        }
    }
}

.main-column.page-content-section {
    &.k-px-0,
    &.k-pr-0 {
        .back-to-task-btn-placeholder {
            right: 0;
        }
    }
}

.back-to-task-btn {
    border-radius: $back-to-task-button-radius;
}

.current-task-icon-wrapper {
    background-color: theme.$kendo-color-secondary;
    color: theme.$wcag-light;
}

.canvas-dependencies-visualization {
    width: 100%;
    height: 100%;
    inset: 0;
    position: absolute;
    z-index: 2;
    transition: opacity 300ms ease-out;
}

.canvas-dependencies-visualization-hidden {
    opacity: 0;
}

.journey-welcome-animation-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    margin-left: -12px;

    width: 440px;
    height: 440px;

    transition: width 300ms ease-out, height 300ms ease-out, top 300ms ease-out, opacity 200ms linear, visibility 200ms linear;
}

.journey-welcome-animation-collapsed {
    width: 32px;
    height: 32px;
    background-color: theme.$kendo-color-primary;
    border: 1px solid rgba(theme.$kendo-component-border, 0.16);
    border-radius: 50%;

    > img {
        display: none !important;
    }
}

.journey-welcome-animation-hidden {
    opacity: 0;
    visibility: hidden;
}

.journey-animating {
    min-height: 440px;

    .journey-arrow,
    .journey-phase-title {
        opacity: 0;
        transition: opacity 400ms linear;
    }

    .journey-start-node,
    .journey-node {
        opacity: 0;
        transition: opacity 200ms linear;

        &-shown {
            opacity: 1;
        }
    }

    &-completed {
        .journey-arrow,
        .journey-phase-title {
            opacity: 1;
        }

        .journey-phase-title.k-text-disabled {
            opacity: 0.6;
        }
    }

    .journey-path-wrapper {
        &::after {
            position: absolute;
            content: '';
            display: block;
            inset: 0;
            background-color: theme.$wcag-light;
            z-index: 1;

            transition: bottom 800ms linear;
        }

        &-shown::after {
            bottom: 100%;
        }
    }
}

.auth-page {
    --mouse-x-offset: 0px;
    --mouse-y-offset: 0px;

    --layer-1-parallax: 0.1;
    --layer-2-parallax: 0.06;
    --layer-3-parallax: 0.05;
    --layer-4-parallax: 0.08;
    --layer-5-parallax: 0.02;
    --layer-6-parallax: 0.01;

    height: 100%;
    overflow-y: auto;
    padding: 0 24px;
    background-image: url('../images/auth/bg-1.svg'), url('../images/auth/bg-2.svg'), url('../images/auth/bg-3.svg'), url('../images/auth/bg-4.svg'),
        url('../images/auth/bg-5.svg'), url('../images/auth/bg-6.svg'), linear-gradient(#06366f 0%, #0a3064 100%);
    background-repeat: no-repeat;
    background-size: 1282.15px 1070.03px, 839.29px 804.37px, 488.09px 435.83px, 538px 517.47px, 562px 508px, 556px 627.35px, auto;
    background-position: right calc(-350px + var(--mouse-x-offset) * var(--layer-1-parallax)) top calc(-890px - var(--mouse-y-offset) * var(--layer-1-parallax)),
        left calc(-160px - var(--mouse-x-offset) * var(--layer-2-parallax)) bottom calc(-650px + var(--mouse-y-offset) * var(--layer-2-parallax)),
        left calc(-390px - var(--mouse-x-offset) * var(--layer-3-parallax)) top calc(50% - var(--mouse-y-offset) * var(--layer-3-parallax)),
        right calc(-5px + var(--mouse-x-offset) * var(--layer-4-parallax)) bottom calc(-150px + var(--mouse-y-offset) * var(--layer-4-parallax)),
        left calc(81% - var(--mouse-x-offset) * var(--layer-5-parallax)) top calc(10px - var(--mouse-y-offset) * var(--layer-5-parallax)),
        right calc(75% + var(--mouse-x-offset) * var(--layer-5-parallax)) top calc(-20px - var(--mouse-y-offset) * var(--layer-5-parallax)), 0 0;
}

.auth-page-content {
    min-height: 100%;
    width: 720px;
    max-width: 100%;
    background-color: theme.$wcag-light;
    margin: 0 auto;
    box-shadow: theme.$kendo-window-shadow;
}

.auth-page-content-section {
    width: 400px;
    max-width: 100%;
}

.cards-grid {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.sort-by-picker {
    width: 136px;
}

.contacts-advanced-filter {
    width: 100%;
}

.advanced-filters-layout {
    grid-template-columns: auto 1fr;
}

.new-contact-dropdown-button-menu {
    width: 156px;
}

.activity {
    --activity-color: 80, 98, 146;
}

.activity-marker {
    background-color: rgb(var(--activity-color));
}

.activity-card-actions-drop-down-button {
    margin: 0 -4px -2px 0;
}

.activity-card-actions-button {
    visibility: hidden;

    &.k-active {
        visibility: visible;
    }
}

.activity-card {
    .activity-marker {
        width: 3px;
    }
}

.activity-card-with-actions {
    transition: box-shadow 200ms ease-in;
    cursor: pointer;

    &:hover {
        box-shadow: theme.$elevated-shadow-sm;

        .activity-card-actions-button {
            visibility: visible;
        }
    }
}

.scheduled-event-container {
    container-type: size;
    container-name: scheduledEvent;

    width: 100%;
    height: 100%;
    background-color: rgba(var(--activity-color), 0.04);
}

.scheduled-event-content {
    display: flex;
    align-items: center;
    gap: 4px;

    .activity-marker {
        width: 3px;
    }

    .scheduled-event-icon {
        flex-shrink: 0;
    }

    .scheduled-event-text {
        flex-grow: 1;
    }
}

@container scheduledEvent (min-height: 53px) {
    .scheduled-event-content {
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100%;

        .activity-marker {
            width: 100%;
            height: 3px;
            grid-row: 1;
            grid-column: 1 / span 2;
        }

        .scheduled-event-icon {
            grid-row: 3;
        }

        .scheduled-event-text {
            grid-row: 2;
            grid-column: 1 / span 2;
            align-self: start;
            max-height: 100%;

            white-space: normal;
        }

        .scheduled-event-people {
            grid-row: 3;
            justify-self: end;
        }
    }
}

.stripe-payment-element-fix {
    // For some reason when the address fields are hidden (address is set to never) for the element, the stripe's iframe height is wrongly calculated to be with 24px more
    margin-bottom: -24px;
}

.cookies-notice-wrapper {
    position: fixed;
    z-index: 10000;
    bottom: 0;
    right: 0;
    padding-inline: 8px;
    width: 431px;
    max-width: 100vw;
}

.cookies-notice {
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.25);
    border-radius: theme.$kendo-border-radius-lg theme.$kendo-border-radius-lg 0 0;
    background-color: theme.$wcag-light;
}

.grecaptcha-badge {
    z-index: 200;
}

.active-filter-limited-length {
    max-width: 20ch;
    display: inline-block;
    vertical-align: bottom;
}

.text-marker {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        display: block;

        right: 0;
        bottom: -6px;
        width: 0;
        height: 0;
        border-top: 6px solid rgba(theme.$kendo-color-info, 0.16);
        border-right: 7px solid transparent;
    }
}

.manage-tags-dialog {
    .k-dialog {
        min-height: 440px;
        max-height: 80vh;
    }
}

.meeting-slot-time-label {
    width: 40px;
}

.new-event-dropdown-button-menu {
    width: 137px;
}

.meeting-proposal-details-column {
    min-width: 240px;
    max-width: 33%;
    padding-right: 16px;
    border-right: 1px solid theme.$kendo-component-border;

    h3 {
        margin-bottom: 24px;
    }

    &--tablet {
        width: 526px;
        max-width: 100%;
        border-bottom: 1px solid theme.$kendo-component-border;
        padding-bottom: 16px;
    }

    &--mobile {
        @extend .meeting-proposal-details-column--tablet;
        align-self: flex-start;
    }
}

.meeting-timeslots-column {
    min-width: 200px;
}

.auto-width-animatable {
    display: grid;
    grid-template-columns: 0fr;
    transition: grid-template-columns 300ms ease-out;
    justify-content: end;
}

.auto-width-animatable-expanded {
    grid-template-columns: 1fr;
}

.in-person-location-option {
    width: 130px;
}

.text-tokens-editor {
    line-height: 24px;
}

.text-token {
    border-radius: 2px;
    border: 1px solid transparent;

    padding-inline: 3px;
    line-height: theme.$kendo-line-height;
    outline: none;

    &-editable {
        cursor: text;

        &-predefined {
            cursor: pointer;
        }
    }
}

.text-token-with-value {
    background-color: rgba(theme.$kendo-color-primary, 0.16);

    &.text-token-editable {
        &:hover {
            border-color: theme.$kendo-picker-hover-border;
        }

        &:focus,
        &:focus-within,
        &.k-selected {
            border-color: theme.$kendo-picker-outline-hover-border;
        }
    }
}

.text-token-no-value {
    border-color: theme.$kendo-picker-outline-hover-border;

    &.text-token-drop-down {
        padding-inline-end: 1px;
    }

    &.text-token-editable {
        &:hover {
            border-color: rgba(theme.$kendo-picker-outline-hover-border, 0.8);
        }

        &:focus,
        &:focus-within,
        &.k-selected {
            border-color: theme.$wcag-dark;
        }
    }
}

.text-token.k-invalid {
    &,
    &:hover,
    &:focus,
    &:focus-within {
        border-color: theme.$kendo-color-error;
    }
}

.hypothesis-research-scale {
    --arrow-height: 12px;
    --negative-arrow-height: calc(var(--arrow-height) * -1);
    --first-column-width: auto;

    display: grid;
    grid-template-columns: var(--first-column-width) auto 1fr;
    align-items: center;

    padding-top: var(--arrow-height);

    &-empty-message {
        grid-column: 3;
        grid-row-start: 1;
    }

    &-loader {
        grid-column: 3;
        grid-row-start: 1;
        place-self: center;
    }
}

.hypothesis-research-scale-condensed {
    --first-column-width: min-content;
}

.hypothesis-research-scale-range {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: translateY(-100%);
        height: var(--arrow-height);
        clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
        background-color: inherit;
    }

    &-lowest {
        clip-path: polygon(0% var(--negative-arrow-height), 0% 100%, 50% calc(100% - var(--arrow-height)), 100% 100%, 100% var(--negative-arrow-height));
    }
}

.task-hypothesis-editor-first-column {
    flex-basis: 280px;
}

.highlighted-list-item {
    display: block;

    .k-list-item-text {
        background-color: rgba(theme.$kendo-color-primary, 0.16);
    }
}

.interview-script-tips-text-area-view {
    background-color: transparent;

    textarea {
        cursor: pointer;
    }
}

.day-slots-grid {
    grid-template-columns: auto 1fr;
}

.research-contact-list-item {
    .add-research-contact-btn {
        display: none;
    }

    &:hover,
    &.k-focus {
        .add-research-contact-btn {
            display: inline-flex;
        }
    }
}

.research-contact-related-user-popup {
    max-width: 160px;
}

.new-contact-dropdown-button-menu {
    max-width: 131px;
}

.interview-execution-icon {
    &-25\% {
        > :nth-child(1) {
            stroke: theme.$kendo-color-secondary;
            stroke-opacity: 1;
        }
    }

    &-50\% {
        > :nth-child(1),
        > :nth-child(2) {
            stroke: theme.$kendo-color-secondary;
            stroke-opacity: 1;
        }
    }

    &-75\% {
        > :nth-child(1),
        > :nth-child(2),
        > :nth-child(3) {
            stroke: theme.$kendo-color-secondary;
            stroke-opacity: 1;
        }
    }

    &-100\% {
        > * {
            stroke: theme.$kendo-color-secondary;
            stroke-opacity: 1;
        }
    }
}

.dashed-stretching-arrow-down {
    width: 6px;
    background-size: 1px 8px;
    background-image: linear-gradient(transparent 50%, currentColor 50%);
    background-repeat: repeat-y;
    background-position: center top;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 5px solid currentColor;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;

        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.interview-entry-card {
    &:hover {
        border-color: theme.$kendo-picker-hover-border;
    }
}

.interview-entry-editor-delete-btn {
    transform: translateX(50%) translateY(-50%);
}

.interview-entry-add-btn {
    .k-button-text {
        width: 100%;
    }

    opacity: 0;

    &:hover,
    &:focus-visible {
        opacity: 1;
    }
}

@keyframes highlight-animation {
    0% {
        box-shadow: none;
    }
    25% {
        box-shadow: var(--highlight-box-shadow);
    }
    75% {
        box-shadow: var(--highlight-box-shadow);
    }
    100% {
        box-shadow: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 650px) {
    .card-information-notice {
        position: static;
        transform: none;
        width: auto;
        text-align: right;

        br {
            display: none;
        }
    }

    .card-information-notice-arrow {
        display: none;
    }
}

.interview-quote-action {
    visibility: hidden;
}

.interview-quote {
    &:hover {
        .interview-quote-action {
            visibility: visible;
        }
    }
}

.message-box {
    border: 1px solid transparent;
    padding: 7px 11px;
}

.interview-stats-layout {
    grid-template-columns: auto auto;
}

.selection-range-dragging-cursor {
    cursor: ew-resize;
}

.selection-range-container {
    --selection-range-handle-shadow-height: 0px;
    --selection-range-start-handle-offset: -2px;

    position: relative;
    user-select: none;
}

.selection-range {
    position: relative;
    background-color: $selection-bg;
    box-shadow: 0px calc(var(--selection-range-handle-shadow-height) * -1) 0px 0px $selection-bg,
        0px var(--selection-range-handle-shadow-height) 0px 0px $selection-bg, var(--selection-range-start-handle-offset) 0px 0px 0px $selection-bg;

    &-handle {
        cursor: ew-resize;
        position: absolute;
        width: 2px;
        height: 1em * theme.$kendo-line-height;
        height: 1lh;
        background-color: theme.$kendo-color-secondary;

        &::before {
            content: '';
            position: absolute;
            bottom: -4px;
            top: 0px;
            left: -4px;
            width: 0px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 6px solid theme.$kendo-color-secondary;
        }

        &-start {
            &::before {
                top: -4px;
                bottom: 0px;
                border-bottom: 0;
                border-top: 6px solid theme.$kendo-color-secondary;
            }
        }
    }
}

.trial-timer-icon {
    --progress-percentage: 0;
    --progress-path-len: 34.317012786865234px;
    --icon-scale: 1;
}

.trial-timer-arrow {
    transform-origin: center;
    transform: rotate(calc(var(--progress-percentage) * -3.05deg));
}

.trial-timer-progress {
    stroke: theme.$kendo-color-error !important;
    --stroke-dash-size: calc(var(--progress-path-len) * var(--icon-scale));
    stroke-dasharray: var(--stroke-dash-size);
    stroke-dashoffset: calc(var(--stroke-dash-size) * (1 - var(--progress-percentage) / 100));
}

.trial-timer-over .trial-timer-end {
    stroke: theme.$kendo-color-error !important;
}

.k-icp-icon-pad {
    display: none;
}

.k-icp-icon-padded .k-icp-icon-pad {
    display: inline;
}

.pick-slot-outline-secondary-button:not(.k-picker .k-input-button) {
    &:hover,
    &.k-hover {
        color: theme.$kendo-color-secondary;
        border-color: currentColor;
        background-color: rgba(theme.$kendo-color-secondary, 0.08);
    }

    &:active,
    &.k-active,
    &.k-selected {
        color: theme.$kendo-color-secondary;
        border-color: currentColor;
        background-color: rgba(theme.$kendo-color-secondary, 0.08);
    }
}

.border-on-hover {
    border: solid 1px transparent;
    &:hover {
        border-color: theme.$kendo-picker-hover-border;
    }
}

.highlight-border-on-hover {
    &:hover {
        border-color: theme.$kendo-picker-hover-border;
    }
}

.oblique-strike-through {
    --strike-color: currentColor;
    --strike-width: 1px;

    background: linear-gradient(
        to bottom right,
        transparent calc(50% - calc(var(--strike-width) / 2)),
        var(--strike-color) calc(50% - calc(var(--strike-width) / 2)),
        var(--strike-color) calc(50% + calc(var(--strike-width) / 2)),
        transparent calc(50% + calc(var(--strike-width) / 2))
    );
}

.oblique-strike-through-bold {
    --strike-width: 2px;
}

@property --progress-percentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
}

.k-icp-donut-progress {
    mask: conic-gradient(#000 calc(var(--progress-percentage, 0) * 1%), transparent 0%) border-box;

    &--animated {
        transition: --progress-percentage 300ms ease-out;
    }
}

.experimental-interview-summary-formatted {
    p {
        margin-bottom: 8px;
    }

    .section {
        margin-bottom: 32px;
    }

    ul,
    ol {
        margin: 0;
        padding-left: 24px;
        margin-bottom: 8px;
    }

    li {
        margin-bottom: 8px;
    }

    li:last-child {
        margin-bottom: 0;
    }
}

.experimental-interview-summary-preview {
    ul,
    ol {
        margin: 0;
        padding-left: 24px;
    }

    li {
        margin: 0;
    }

    li:last-child {
        margin-bottom: 0;
    }
}

.experimental-interviews-grid {
    .k-grid-header col:nth-of-type(1) {
        width: 40%;
    }
    .k-grid-table col:nth-of-type(1) {
        width: 40%;
    }
}

@property --ai-border-fill-base {
    syntax: '<angle>';
    inherits: false;
    initial-value: 0deg;
}

// Using degrees due to bug in the CSS minifier.
// It strips the % sign but 0 is not valid as initial value in properties.
@property --ai-border-fill-start {
    syntax: '<percentage> | <angle>';
    inherits: false;
    initial-value: 0deg;
}

@property --ai-border-fill-stop {
    syntax: '<percentage> | <angle>';
    inherits: false;
    initial-value: 360deg;
}

.ai-border {
    position: absolute;
    inset: 0;
    background-image: linear-gradient(90deg, #e0cc00 0%, #ff00ff 45%, #3158ff 80%);
    background-repeat: no-repeat;
    background-origin: border-box;
    mask: conic-gradient(
            from var(--ai-border-fill-base, 0deg),
            transparent 0% var(--ai-border-fill-start, 0deg),
            #000 var(--ai-border-fill-start, 0deg) var(--ai-border-fill-stop, 360deg),
            transparent var(--ai-border-fill-stop, 360deg)
        ),
        linear-gradient(#000 0 0) padding-box;

    mask-composite: subtract;

    &--purple {
        background-image: linear-gradient(90deg, #d214ff 0%, #3259ff 100%);
    }

    &--pulse {
        animation: border-pulse 600ms ease-out alternate infinite;
    }

    &--draw-infinite {
        animation: ai-border-draw-switch 1300ms ease-in-out infinite -650ms; // The delay is negative so the animation is halfway through and start from no border and and with drawn border
    }
}

.opacity-subtle-pulse {
    animation: opacity-subtle-pulse-frames 600ms ease-out alternate infinite;
}

@keyframes opacity-subtle-pulse-frames {
    0% {
        opacity: 0.6;
    }

    100% {
        opacity: 0.16;
    }
}

@keyframes border-pulse {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.16;
    }
}

@keyframes ai-border-draw-switch {
    0% {
        --ai-border-fill-base: 0deg;
        --ai-border-fill-start: 0%;
        --ai-border-fill-stop: 100%;
    }

    50% {
        --ai-border-fill-base: 360deg;
        --ai-border-fill-start: 0%;
        --ai-border-fill-stop: 0%;
    }

    100% {
        --ai-border-fill-base: 360deg;
        --ai-border-fill-start: 0%;
        --ai-border-fill-stop: 100%;
    }
}

.ai-text-color {
    background-image: linear-gradient(90deg, #d214ff, #3259ff);
    color: transparent;
    background-clip: text;
}

.ai-background {
    background-image: linear-gradient(90deg, #fbecff, #eff2ff);
}

.bg-ghost-gradient {
    background-image: linear-gradient(to bottom right, rgba(theme.$wcag-light, 0.5), rgba(theme.$wcag-light, 0.85));
}

.chat-message-textbox {
    &:focus,
    &:focus-within,
    &.k-focus,
    &.k-selected {
        .k-input,
        .k-textarea {
            border-color: theme.$kendo-color-secondary;
        }
    }

    .padded-textarea.k-textarea > .k-input-inner {
        padding: 8px 8px 38px 12px;
        max-height: 25vh;
    }
}

.chat-options-block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(240px, 100%), 1fr));
    gap: 16px;
}

.chat-to-bottom-arrow {
    position: absolute;
    top: -8px;
    right: 50%;
    transform: translateX(50%) translateY(-100%);
    z-index: 999;
}

@media screen and (max-width: 576px) {
    .chat-to-bottom-arrow {
        top: -5px;
    }
}

@media (hover: none) and (pointer: coarse) {
    .hide-scrollbar-on-touch-device {
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.predefined-action {
    min-width: 180px;
    max-width: 280px;
}

.placeholder-q-or-s {
    color: theme.$kendo-input-placeholder-text;
    position: absolute;
    z-index: 999;
}

.placeholder-q-or-s-slash {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('../icons/forward-slash.svg') no-repeat;
    vertical-align: middle;
}

.interview-script-editor {
    .k-editor-content > .ProseMirror {
        padding: 28px 32px;
        overflow-wrap: anywhere;
    }

    .ProseMirror {
        h2 {
            margin: 48px 0 16px;
        }

        p {
            margin: 16px 0;
        }

        h2,
        p {
            position: relative;

            &:hover {
                .side-button-marker > .side-menu-button {
                    visibility: visible;
                }
            }

            &.placeholder:before {
                content: attr(data-placeholder);
                float: left;
                color: theme.$kendo-input-placeholder-text;
                cursor: text;
                height: 0;
                pointer-events: none;
            }
        }

        .ProseMirror-selectednode {
            background-color: theme.$kendo-base-bg;
            outline: none;
        }
    }
}

.side-button-marker {
    position: absolute;
    display: flex;
    align-items: flex-start;
    min-height: 100%;
    right: 100%;
    top: 0;
}

.side-button-marker:hover > .side-menu-button,
.side-button-marker > .side-menu-button:has(.k-active) {
    visibility: visible;
}

.side-button-marker > .side-menu-button {
    visibility: hidden;
    margin-top: -1px;
}

.interview-script-document-side-popup .k-menu-link {
    padding-inline: 4px;
}

.k-editor-toolbar {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    margin: auto;
    padding: 5px 7px;
    gap: 8px;
    width: fit-content;
    flex-wrap: nowrap;
    border: 1px solid theme.$kendo-base-border;
    box-shadow: theme.$elevated-shadow-sm;
    border-radius: 8px;
    background-color: theme.$kendo-component-bg;
    z-index: 10;
}

.k-i-undo::before,
.k-i-redo::before,
.k-i-file-pdf::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.k-i-undo::before {
    background-image: url('../icons/undo.svg');
}

.k-i-redo::before {
    background-image: url('../icons/redo.svg');
}

.k-i-file-pdf::before {
    background-image: url('../icons/download.svg');
}

.rotating-element {
    animation: rotate-animation 1.2s linear infinite;
}

@keyframes rotate-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.k-pdf-export {
    height: 100%;
}

.k-pdf-export h2 {
    margin: 0;
    font-size: 14px;
}

.k-pdf-export p {
    font-size: 12px;
    margin: 12px 0;
}

.k-pdf-export .placeholder,
.k-pdf-export .placeholder-q-or-s {
    display: none;
}

.k-icp-message-stamp {
    padding: 3px 12px;
    background-color: theme.$link-text-alt;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.interview-notes-editor {
    .k-editor-content > .ProseMirror {
        overflow: hidden;
        padding: 0px 16px 16px 16px;
        flex: 1 1 0;
    }
}

.document-editor-no-overflow {
    .k-editor-content {
        overflow: visible;
    }

    .k-editor-content > .ProseMirror {
        overflow: visible;
    }
}

.dialog-window-max-h-half {
    .k-window {
        max-height: 50vh;
    }
}

.dialog-window-h-half {
    .k-window {
        height: 50vh;
    }
}

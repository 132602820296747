.-mw100 {
    max-width: 100%;
}

.-w1 {
    width: 400px;
}

.-w2 {
    width: 480px;
}

.-w3 {
    width: 560px;
}

.-wa {
    width: auto;
}

.-w100 {
    width: 100%;
}

.-maxw1 {
    max-width: 400px;
}

.-maxw-16 {
    max-width: 64px;
}

.-maxw-160 {
    max-width: 640px;
}

.-minw0 {
    min-width: 0;
}

.-h100 {
    height: 100%;
}

.-minh100 {
    min-height: 100%;
}

.-maxh100 {
    max-height: 100%;
}

.responsive-image {
    max-width: 100%;
    height: auto;
}
.-bw {
    overflow-wrap: anywhere;
}

.-\!pr05 {
    padding-right: 2px !important;
}

.-block-center {
    margin-left: auto;
    margin-right: auto;
}

.-zia {
    z-index: auto;
}

.-zi-1 {
    z-index: -1;
}

.-zi9999 {
    z-index: 9999;
}

.-on {
    outline: none;
}

.-vam {
    vertical-align: middle;
}

.-inline-center-container::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.-mt-0\.75 {
    margin-top: 3px;
}

.-mt-5px {
    margin-top: 5px;
}

.-mt-1\.5 {
    margin-top: 6px;
}

.-mb-0\.75 {
    margin-bottom: 3px;
}

.-mr-1\.5 {
    margin-right: 6px;
}

.-px-0\.75 {
    padding-inline: 3px;
}

.-px-1\.5 {
    padding-inline: 6px;
}

.-py-0\.75 {
    padding-block: 3px;
}

.-py-1\.75 {
    padding-block: 7px;
}

.k-border-5 {
    border-width: 5px;
}

.-last-child-mb-0 > :last-child {
    margin-bottom: 0;
}

.-right--12 {
    right: -48px;
}

.-top--2 {
    top: -8px;
}

.-flex-2 {
    flex: 2 1 0;
}

.-sg-s {
    scrollbar-gutter: stable;
}

html,
body,
#root {
    height: 100%;
}

.page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.page-content {
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
}

.page-content-columns {
    display: flex;
    align-items: stretch;
}

.top-nav {
    height: 64px;
}

.side-nav {
    width: 64px;
    flex-shrink: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.main-column {
    flex-grow: 1;
    max-height: 100%;
    overflow-y: auto;
    position: relative;
}

.side-panel {
    width: 320px;
    height: 100%;
    overflow-y: auto;
}

.page-content-section {
    padding: 24px;
}

.heading-row,
h1.heading-row {
    margin-bottom: 24px;
}

.page-content-middle {
    margin: 0 auto;
    width: 640px;
    max-width: 100%;
}

.page-content-middle--medium {
    width: 620px;
}

.page-content-middle--small {
    width: 420px;
}

.page-content--large {
    width: 680px;
}

.page-content--xl {
    width: 800px;
}

.page-content--xxl {
    width: 900px;
}

.canvas-page {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.vertical-scrolling-area {
    overflow-y: auto;
    padding-right: 24px;
    padding-bottom: 24px;
}
